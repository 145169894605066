import React from 'react';

import { Whoops, WhoopsReason } from 'src/components/whoops';

function convertStatusCodeToReason(statusCode) {
  let reason = WhoopsReason.default;

  if (statusCode === 404) {
    reason = WhoopsReason.pageNotFound;
  }

  if (statusCode >= 500 && statusCode < 600) {
    reason = WhoopsReason.serverError;
  }

  return reason;
}

function Error() {
  const reason = convertStatusCodeToReason(404);

  return <Whoops reason={reason} />;
}

export default Error;
